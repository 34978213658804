import React from 'react';
import HomePage from '../HomePage/HomePage';
import './App.css';

export default function App() {
  return (
    <main className="App">
        <HomePage />
    </main>
  );
}